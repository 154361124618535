import React from 'react'
import { VStack, Text, Button, Heading, Image, HStack } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import usePublishedMedia from '../../../hooks/usePublishedMedia'
import useVideoSize from '../../../hooks/useVideoSize'

const ModalDownloadVideo = ({ onClose, isOpen }) => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/vidday-theatre-download-video-modal',
		ext: 'png',
	})

	const publishedMedia = usePublishedMedia()
	/** Build download URL */
	const downloadURL = publishedMedia?.signedDownloadLocationUrl

	const size = useVideoSize()

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={img.src} srcSet={img.srcset} alt="Download Video" />
						<Heading as="h3" size="lg" variant="hero" color="dark">
							Download video?
						</Heading>
						<Text color="dark">
							Ensure you're connected to WiFi as you're about to download a large file.
						</Text>
						<Text color="dark">
							<strong>MP4 {size && `- ${size}`}</strong>
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button as="a" title="Click to download" href={downloadURL} download={downloadURL}>
							Download
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalDownloadVideo
