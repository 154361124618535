import { Box, Container, Heading, VStack, HStack, SimpleGrid, Divider, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import BackButton from '../../atoms/nav-controls/BackButton'
import NavBar from '../../atoms/NavBar'
import FooterBase from '../../molecules/FooterBase'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import { DownloadVideoSelector } from './selectors/DownloadVideoSelector'
import KeepsakeCards from '../Keepsake/atoms/KeepsakeCards'
import ModalDownloadVideo from '../../organisms/ModalDownloadVideo'

const Controls = ({ onDownload }) => {
	const navigate = useNavigate()
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)

	return (
		<NavBar position="fixed">
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => navigate(handlers.root)} />
			</HStack>
			{/* <HStack justifyContent="flex-end">
				<DownloadButton onClick={onDownload} />
			</HStack> */}
		</NavBar>
	)
}

const Download = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const downloadModal = useDisclosure()

	return (
		<>
			<Controls onDownload={downloadModal.onOpen} />

			<Container maxW="container.lg" py="1rem">
				<Box mt="1rem" mb="1.5rem" textAlign="center">
					<Heading variant="hero">Save the Video</Heading>
				</Box>
				<VStack spacing="2rem" w="full">
					<SimpleGrid columns={[1]} spacing="1rem" w="full">
						<DownloadVideoSelector event={event} onClick={downloadModal.onOpen} />
					</SimpleGrid>

					<Flex w="full" alignItems="center">
						<Divider orientation="horizontal" borderStyle="dashed" />
					</Flex>
					<KeepsakeCards displayAsSlider={false} />
				</VStack>
				<FooterBase />
				<ModalDownloadVideo isOpen={downloadModal.isOpen} onClose={downloadModal.onClose} />
			</Container>
		</>
	)
}

export default Download
