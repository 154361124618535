import { useState, useEffect } from 'react'
import usePublishedMedia from './usePublishedMedia'
import axios from 'axios'

/**
 * Convert bytes to human readable string. Rounds up to 2 decimal positions
 */
const humanReadable = (bytes) => {
	if (bytes == 0) {
		return '0.00 B'
	}
	const e = Math.floor(Math.log(bytes) / Math.log(1024))
	return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
}

const useVideoSize = () => {
	const video = usePublishedMedia()
	const [size, setSize] = useState('')

	useEffect(() => {
		if (video) {
			if (video.signedDownloadLocationUrl) {
				// console.log(video.signedDownloadLocationUrl, 'url video')
				axios
					.head(video.signedDownloadLocationUrl)
					.then((response) => {
						const bytes = response.headers['content-length']
						const fileSize = humanReadable(bytes)
						setSize(fileSize)
					})
					.catch((error) => console.log(error))
			}
		}
	}, [video])

	return size
}

export default useVideoSize
